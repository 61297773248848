import React from 'react';
import { setImageObjectJsonLd, useSeo } from '@bamboo/core-lib/repo/seo';

type ImageObjectJsonLdProps = {
  id: string;
  url: string;
  contentUrl: string;
  width?: number;
  height?: number;
};

const ImageObjectJsonLd = (props: ImageObjectJsonLdProps) => {
  const { id, url, contentUrl, width = 1920, height = 1080 } = props;
  const { imageObjectJsonLd } = useSeo();

  const newImageObjectJsonLd = {
    '@type': 'ImageObject',
    '@id': id,
    inLanguage: 'pt-BR',
    url,
    contentUrl,
    width,
    height,
  };

  React.useEffect(() => {
    if (imageObjectJsonLd === undefined) {
      setImageObjectJsonLd(newImageObjectJsonLd);
    }
  }, [imageObjectJsonLd]);

  if (typeof window === 'undefined') {
    setImageObjectJsonLd(newImageObjectJsonLd);
  }

  return null;
};

export default ImageObjectJsonLd;
